/** @jsx jsx */
import { css } from '@emotion/core';
import { mq } from "../constants/styleConstants";

export const photoStyle = {
  photoBlock: css`
    margin: 7rem auto 4rem;
    padding: 0;
    ${mq('medium')} {
      margin-top: 9rem;
      margin-bottom: 8rem;
    }
  `,
  slideShowThumbList: css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 0;
  `,
  slideShowThumbListItem: css`
    width: 50%;
    ${mq('medium')} {
      width: 33.333%;
    }
  `,
}
