import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link";
import { gsap } from "gsap";

// import Layout from "../components/layout"
import SEO from "../components/seo"
import { photoStyle } from "../components/photoStyle";

import Img from "gatsby-image";
import TransitionCover from "../components/TransitionCover/transitionCover";
import GlobalContext from "../context/globalContext";
import SlideShowThumb from "../components/SlideShow/slideShowThumb";
import SlideShow from "../components/SlideShow/slideShow";
import { duration, entryDelay, imageFadeDuration } from "../constants/componentConstants";
import PageTitle from "../components/PageTitle/pageTitle";

const PhotoPage = () => {
  const data = useStaticQuery(graphql`
  query {
    slideImages: allFile(
      sort: { fields: [base] }
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "photo" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 2880) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    },
    slideThumbImages: allFile(
      sort: { fields: [base] }
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "photo" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`);

  const global = useContext(GlobalContext);
  useEffect(() => {
    global.setHideHeaderLogo(false);
    global.setEntryAnimation(entryAnimation);
    global.setExitAnimation(exitAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startSlide, setStartSlide] = useState(0);
  const commonLayer = useRef(null);
  const entryAnimation = useCallback(() => {
    console.log("entryAnimation");
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 100,
      })
      .to(commonLayer.current, {
        xPercent: 200,
        ease: "power2.in",
        duration: duration,
        delay: entryDelay,
        onComplete: () => {
          commonLayer.current.style.display = "none";
        }
      })
  }, []);
  const exitAnimation = useCallback(({ node }) => {
    console.log("exitAnimation");
    commonLayer.current.style.display = "block";
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 0,
      })
      .to(commonLayer.current, {
        xPercent: 100,
        ease: "power2.out",
        duration: duration,
      })
      .to(node, {
        opacity: 0,
        duration: 0,
      })
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const openModal = (index = 0) => {
    setStartSlide(index);
    console.log(index);
    setIsModalOpen(true);
  }

  const openSlideShow = (index) => {
    openModal(index);
  }

  const slideShowThumbStyle = { blockStyle: photoStyle.slideShowThumbListItem };

  return (
    <>
      <SEO title="PHOTO" />

      <div css={photoStyle.photoBlock}>
        <PageTitle>PHOTO</PageTitle>

        <div css={photoStyle.slideShowThumbList}>
          {data.slideThumbImages.edges.map((image, index) => (
            <SlideShowThumb
              onClick={openSlideShow}
              index={index}
              role="button"
              styles={slideShowThumbStyle}
              key={index}
            >
              <Img
                durationFadeIn={imageFadeDuration}
                fluid={image.node.childImageSharp.fluid}
                alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
                // style={{ width: "100%", maxHeight: "100%" }}
                // imgStyle={{ objectFit: "contain" }}
              />
            </SlideShowThumb>
          ))}
        </div>
      </div>

      <SlideShow
        open={isModalOpen}
        images={data.slideImages}
        startSlide={startSlide}
        closeModal={closeModal}
      />


      <TransitionPortal>
        <TransitionCover ref={commonLayer} />
      </TransitionPortal>
    </>
  )
}

export default PhotoPage;
